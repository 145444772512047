import React from "react";
import Footers from "../components/footer";
import Headers from "../components/headers";
import Helmetz from "../components/helmet";
import SOC from '../images/soc.jpg';
import TEDx from '../images/tedx.jpg';
import Ast from '../images/astrobook.jpg';
import YOURLS from '../images/yourls.png';
import Afastron from '../images/Afastron.png';
import Moodle from '../images/Moodle.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import PCard from "../components/projectCard";

export default function Projects(){
    return(
        <main className=" bg-slate-300">
            <Helmetz />
            <Headers />
            <h1 className="mt-16 pt-4 text-center lg:text-5xl text-4xl font-bold" style={{fontFamily: 'Amatic SC', fontSize: '150'}}>
                My Projects
            </h1>
            <div className="content-evenly grid grid-cols-1 lg:grid-cols-2 grid-flow-row pt-16 px-32">
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Astronomy Module (In Bahasa Indonesia)"
                    img={Ast}          
                    url=
                      "https://drive.google.com/file/d/1SwDk684OtAG5o384Xsg0-vkmGYUg4X46/view?usp=sharing"              
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Smakonecup's Website"
                    img={SOC}
                    url="https://soc.smakone.org"
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="TEDxYouth@Smakone's Website"
                    img={TEDx}
                    url="https://tedxyouthsmakone.com"
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Smakone's URL Shortener"
                    img={YOURLS}
                    url= "https://url.smakone.org"
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Afastron"
                    img={Afastron}          
                    url=
                      "https://afastron.org"              
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Afastron's Moodle"
                    img={Moodle}          
                    url=
                      "https://moodle.afastron.org"              
                    />
                </div>
                <div className="place-self-center mb-8">
                    <PCard 
                    title="Afastron's URL Shortener"
                    img={YOURLS}
                    url= "https://url.afastron.org"
                    />
                </div>
            </div>
            <Footers />
        </main>
    )
}