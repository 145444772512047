import React from "react";
import { Card, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function PCard(props){
    return(
                <Card className="w-96 h-88">
                <Card.Img variant="top" src={props.img} />
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Button variant="primary">
                        <a href={props.url} className="text-white" target="_blank"
                        style={{textDecoration: 'none'}}>View Project!</a>
                    </Button>
                </Card.Body>
                </Card>
    )
}